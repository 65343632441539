import axios from 'axios'

class ApplicationService {

    constructor() {
        this.baseUrl = process.env.VUE_APP_SERVER_HOST + 'application'

        if(process.env.VUE_APP_AUTH_TOKEN) {
            axios.defaults.headers.common['Authorization'] = process.env.VUE_APP_AUTH_TOKEN
        }
    }

    getModules() {
        return new Promise((resolve, reject) => {
            axios({
                url: this.baseUrl + '/modules',
                method: 'GET',
                isPrior: true
            })
                .then(resp => { resolve(resp.data) })
                .catch(err => { reject(err) })
        })
    }

    getArticleFromOldId(oldId) {
        return new Promise((resolve, reject) => {
            axios({
                url: this.baseUrl + '/get-new-article-id/' + oldId,
                method: 'GET'
            })
                .then(resp => { resolve(resp.data) })
                .catch(err => { reject(err) })
        })
    }

    getSkillFromOldId(oldId) {
        return new Promise((resolve, reject) => {
            axios({
                url: this.baseUrl + '/get-new-skill-id/' + oldId,
                method: 'GET'
            })
                .then(resp => { resolve(resp.data) })
                .catch(err => { reject(err) })
        })
    }

    getPropositionFromOldId(oldId) {
        return new Promise((resolve, reject) => {
            axios({
                url: this.baseUrl + '/get-new-proposition-id/' + oldId,
                method: 'GET'
            })
                .then(resp => { resolve(resp.data) })
                .catch(err => { reject(err) })
        })
    }

    getProjectFromOldId(oldId) {
        return new Promise((resolve, reject) => {
            axios({
                url: this.baseUrl + '/get-new-project-id/' + oldId,
                method: 'GET'
            })
                .then(resp => { resolve(resp.data) })
                .catch(err => { reject(err) })
        })
    }

    getRequestFromOldId(oldId) {
        return new Promise((resolve, reject) => {
            axios({
                url: this.baseUrl + '/get-new-request-id/' + oldId,
                method: 'GET'
            })
                .then(resp => { resolve(resp.data) })
                .catch(err => { reject(err) })
        })
    }

    getUserFromOldId(oldId) {
        return new Promise((resolve, reject) => {
            axios({
                url: this.baseUrl + '/get-new-user-id/' + oldId,
                method: 'GET'
            })
                .then(resp => { resolve(resp.data) })
                .catch(err => { reject(err) })
        })
    }

}

export default new ApplicationService
